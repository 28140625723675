<template>
  <div style="margin-top: 120px;">
    <search-nav :active="0"></search-nav>
    <el-row>
      <div class="container">
        <!--企业认证-->
        <div class="condition-content" style="margin-bottom: 30px" >
          <div class="dis-bet-cen condition-title">
            <span class="screening-conditions-title" style="font-size: 16px;color: #666666;">企业认证</span>
            <link-to-advanced></link-to-advanced>
          </div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="right" label-width="400px" style="margin-top: 50px" >
            <el-form-item label="上传营业执照：" prop="imageUrl">
              <div class="dis-cen-cen" style="width: 326px; height: 175px; border: 1px solid #E5E5E5;">
                <div class="dis-cen-cen" style="width: 191px; height: 115px;">
                  <el-upload class="avatar-uploader" :show-file-list="false" :before-upload="beforeAvatarUpload">
                    <el-image v-if="ruleForm.imageUrl" class="dis-cen-cen" style="width: 326px; height: 175px; margin-top: 16px" :src="ruleForm.imageUrl" fit="contain"></el-image>
                    <el-image v-if="!ruleForm.imageUrl" class="dis-cen-cen" style="width: 181px; height: 105px;margin-top: 16px" :src="backgroundImage" fit="contain"></el-image>
                  </el-upload>
                </div>
              </div>
              <p style="width: 404px;font-size: 14px;color: #CCCCCC;line-height:15px;text-align: left;margin-top: 10px;">请上传真实的营业执照,保证图像清晰(不缺失边框,不模糊，不闪光 强烈)，支持jpg、png、bmp等格式，图片大小不超过5M</p>
              <el-input v-show="false" placeholder="请输入公司名称" class="dis-sta-cen" style="width: 440px;" v-model="ruleForm.imageUrl" ></el-input>
            </el-form-item>
            <el-form-item label="公司名称：" prop="companyName">
              <el-input placeholder="请输入公司名称" class="dis-sta-cen" style="width: 440px;" v-model="ruleForm.companyName" ></el-input>
            </el-form-item>
            <el-form-item label="社会统一信用代码：" prop="creditCode">
              <el-input placeholder="请输入社会统一信用代码" class="dis-sta-cen" style="width: 440px;" v-model="ruleForm.creditCode" ></el-input>
            </el-form-item>
            <el-form-item label="法定代表人：" prop="legalPerson">
              <el-input placeholder="请输入法定代表人姓名" class="dis-sta-cen" style="width: 440px;" v-model="ruleForm.legalPerson" ></el-input>
            </el-form-item>
            <el-form-item label="法定代表人身份证号：" prop="legalPersonId">
              <el-input placeholder="请输入法定代表人身份证号" class="dis-sta-cen" style="width: 440px;" v-model="ruleForm.legalPersonId" ></el-input>
            </el-form-item>
            <el-form-item>
              <p class="dis-sta-cen" style="width: 404px;font-size: 12px;color: #E33244;line-height:0px;text-align: left;margin-top: 10px">请核对以上信息识别是否正确，如有错误可自行修改</p>
            </el-form-item>
            <el-form-item class="dis-sta-cen">
              <el-checkbox style="margin-left: -450px;" v-model="ruleForm.isAgree"></el-checkbox>
              <span style="margin-left: 5px;">请仔细阅读<span @click="agreementClick()" style="cursor: pointer;color: #5D6FE9;">《企业征信授权书》</span>，一经勾选视为同意</span>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="login-btn dis-cen-cen" @click="submitForm()"
                         :disabled="!ruleForm.isAgree">立即认证</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import { formTrim } from '@/utils/index' // 工具类
import { mapGetters } from 'vuex'
import { getCompanyInfoByCode } from '@/api/user.js'
import {
  oldForwardBID,
  forwardFile
} from '@/api/forward.js'
export default {
  name: 'company-certification',
  data () {
    return {
      ruleForm: {
        imageUrl: '', // 图片地址
        companyName: '', // 公司名称
        creditCode: '', // 统一社会信用代码
        legalPerson: '', // 法人
        legalPersonId: '', // 法人身份证
        businessDeadlineStart: '', // 营业期限
        businessDeadline: '', // 营业期限
        businessScope: '', // 经营范围
        businessAddress: '', // 地址
        isAgree: false
      },
      rules: {
        imageUrl: [
          { required: true, message: '请上传营业执照', trigger: 'change' }
        ],
        companyName: [
          { required: true, message: '请输入公司名称', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        creditCode: [
          { required: true, message: '请输入社会统一信用代码', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        legalPerson: [
          { required: true, message: '请输入法定代表人', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        legalPersonId: [
          { required: true, message: '请输入法定代表人身份证号', trigger: 'change' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ]
      },
      imageUrl: '',
      backgroundImage: require('@/assets/images/business_license_upload.png'),
      loading: '',
      userInfos: {}
    }
  },
  computed: {
    // vuex获取值
    // this.$store.user.vipData = obj
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo',
      'customerServiceTel'
    ])
  },
  mounted () {
    const vm = this
    vm.$refs.ruleForm.resetFields()
  },
  methods: {
    // 用户信息存放缓存中
    saveUserInfoToSession () {
      const that = this
      const resultData = that.userInfos
      getCompanyInfoByCode({
        code: resultData.taxpayerNumber
      }).then(result => {
        if (result.code === 0) {
          const res = result.data
          resultData.companyId = res.data?.id
          that.$store.dispatch('setUserInfo', JSON.stringify(resultData))
          if (result.data.code === 1) {
            const data = {
              dateBackgroundUrl: '/business/updateBusinessData',
              dateBackgroundRequestType: 'get',
              data: {
                companyName: resultData.enterpriseName,
                type: 0,
                appId: 'b63ad42c7e6c49979529237dd93f1c57'
              }
            }
            oldForwardBID(data).then(result => {
              console.log(result)
            })
          }
        }
      }).catch(error => {
        console.log(error)
      })
    },
    submitForm () {
      const vm = this
      vm.ruleForm = formTrim(vm.ruleForm)
      vm.$refs.ruleForm.validate((valid) => {
        vm.loading = vm.$loading({
          lock: true,
          text: '验证中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        if (valid) {
          const data = {
            dateBackgroundUrl: '/user/user/submitBusinessInformationCC',
            dateBackgroundRequestType: 'post',
            data: {
              businessLicenceFilePath: vm.ruleForm.imageUrl,
              taxpayerNumber: vm.ruleForm.creditCode,
              legalRepresentative: vm.ruleForm.legalPerson,
              enterpriseName: vm.ruleForm.companyName,
              businessAddress: vm.ruleForm.businessAddress,
              businessDeadlineStart: vm.ruleForm.businessDeadlineStart,
              businessDeadline: vm.ruleForm.businessDeadline,
              businessScope: vm.ruleForm.businessScope,
              enterpriseContactIdentityCardNumber: vm.ruleForm.legalPersonId,
              legalRepresentativeMobile: JSON.parse(vm.userInfo).enterpriseContactMobile
            }
          }
          oldForwardBID(data).then(result => {
            console.log(result)
            const thisUserInfo = JSON.parse(vm.userInfo)
            if (result.code === 0) {
              if (vm.loading) {
                vm.loading.close()
                vm.loading = ''
              }
              ElMessage.success({ message: '认证成功！', offset: 60 })
              // 认证成功
              thisUserInfo.businessInformationStatus = '1'
              const datas = {
                dateBackgroundUrl: '/bid/user/accountInfo',
                dateBackgroundRequestType: 'post',
                data: {
                  idToken: vm.idToken
                }
              }
              oldForwardBID(datas).then(results => {
                if (results.code === 0) {
                  const ress = results.data
                  vm.userInfos = ress
                  vm.saveUserInfoToSession()
                  vm.$store.dispatch('setUserInfo', JSON.stringify(thisUserInfo))
                  const itemStr = sessionStorage.getItem('company-certification-name')
                  setTimeout(function () {
                    vm.$router.push({
                      name: itemStr
                    })
                  }, 3000)
                }
              })
            } else if (result.code === 1 && result.msg.indexOf('已经存在') >= 0) {
              if (vm.loading) {
                vm.loading.close()
                vm.loading = ''
              }
              const mobile = result.data.mobile ? result.data.mobile : ''
              let n = ''
              if (mobile) {
                n = mobile.substring(0, 3) + '****' + mobile.substring(7, 11)
              }
              const str = '您的公司已经注册认证，请联系公司人员' + n + '或拨打平台客服热线' + vm.customerServiceTel
              ElMessageBox.alert(str, '温馨提示', {
                confirmButtonText: 'OK',
                callback: (action) => {
                  console.log(action)
                }
              })
            } else {
              if (vm.loading) {
                vm.loading.close()
                vm.loading = ''
              }
              ElMessage.error(result.msg)
            }
          }).catch(error => {
            if (vm.loading) {
              vm.loading.close()
              vm.loading = ''
            }
            console.log(error)
            ElMessage.error('企业认证失败！')
          })
        }
      })
    },
    agreementClick () {
      const vm = this
      sessionStorage.removeItem('about-item')
      sessionStorage.setItem('about-item', 'creditAuthorization')
      const routeData = vm.$router.resolve({
        name: 'about'
      })
      window.open(routeData.href, '_blank')
    },
    beforeAvatarUpload (file) {
      const vm = this
      // 文件重命名
      const oldFileName = file.name
      const oldFileNameArr = oldFileName.split('.')
      const newFileName = new Date().getTime() + '' + Math.ceil(Math.random() * 1000) + '.' + oldFileNameArr[oldFileNameArr.length - 1]
      const newFile = new File([file], newFileName, { // 文件不能直接重命名， 要重新new 一个file
        type: file.type
      })
      const isJPG = newFile.type.toLowerCase() === 'image/jpeg' ||
        newFile.type.toLowerCase() === 'image/jpg' ||
        newFile.type.toLowerCase() === 'image/png' ||
        newFile.type.toLowerCase() === 'image/bmp'
      const isLt2M = newFile.size / 1024 / 1024 < 5
      if (!isJPG) {
        ElMessage.warning({
          message: '上传营业执照支持 JPG PNG BMP 格式!',
          type: 'warning',
          offset: 60
        })
        return false
      }
      if (!isLt2M) {
        ElMessage.warning({
          message: '上传营业执照大小不能超过 5MB!',
          type: 'warning',
          offset: 60
        })
        return false
      }
      if (isJPG && isLt2M) {
        // 可以上传
        vm.loading = vm.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        const form = new FormData()
        form.append('file', newFile)
        forwardFile(form).then(res => {
          if (vm.loading) {
            vm.loading.close()
            vm.loading = ''
          }
          if (res.code === 0) {
            this.ruleForm.imageUrl = res.data?.downPath
            if (!this.ruleForm.imageUrl) {
              return ElMessage.error('营业执照上传失败')
            }
            const data = {
              dateBackgroundUrl: '/bid/enterprise/ocrBusinessLicens',
              dateBackgroundRequestType: 'post',
              data: {
                requestUrl: res.data?.downPath
              }
            }
            oldForwardBID(data).then(result => {
              if (result.code === 0) {
                const items = result.data
                if (items) {
                  vm.ruleForm.creditCode = items.regNum
                  vm.ruleForm.legalPerson = items.person
                  vm.ruleForm.companyName = items.name
                  const time = items.period.replace(/\s/g, '').split('至')
                  if (time.length === 2) {
                    if (time[1] === '长期') {
                      const date0 = time[0].replace(/(.+?)年(.+?)月(.+)日/, '$1-$2-$3')
                      vm.ruleForm.businessDeadlineStart = new Date(date0).getDate() ? date0 : ''
                      vm.ruleForm.businessDeadline = ''
                      // this.form.fixedTerm = '0'
                    } else {
                      const date0 = time[0].replace(/(.+?)年(.+?)月(.+)日/, '$1-$2-$3')
                      const date1 = time[1].replace(/(.+?)年(.+?)月(.+)日/, '$1-$2-$3')
                      vm.ruleForm.businessDeadlineStart = new Date(date0).getDate() ? date0 : ''
                      vm.ruleForm.businessDeadline = new Date(date1).getDate() ? date1 : ''
                      // this.form.fixedTerm = '1'
                    }
                  }
                  vm.ruleForm.businessScope = items.business
                  vm.ruleForm.businessAddress = items.address
                }
                // const items = result.data.items
                // for (let i = 0; i < items.length; i++) {
                //   const itemss = (items[i].itemstring).trim()
                //   if (items[i].item === '注册号') {
                //     vm.ruleForm.creditCode = itemss
                //   }
                //   if (items[i].item === '法定代表人') {
                //     vm.ruleForm.legalPerson = itemss
                //   }
                //   if (items[i].item === '公司名称') {
                //     vm.ruleForm.companyName = itemss
                //   }
                //   if (items[i].item === '营业期限') {
                //     const time = itemss.replace(/\s/g, '').split('至')
                //     if (time.length === 2) {
                //       if (time[1] === '长期') {
                //         const date0 = time[0].replace(/(.+?)年(.+?)月(.+)日/, '$1-$2-$3')
                //         vm.ruleForm.businessDeadlineStart = new Date(date0).getDate() ? date0 : ''
                //         vm.ruleForm.businessDeadline = ''
                //         // this.form.fixedTerm = '0'
                //       } else {
                //         const date0 = time[0].replace(/(.+?)年(.+?)月(.+)日/, '$1-$2-$3')
                //         const date1 = time[1].replace(/(.+?)年(.+?)月(.+)日/, '$1-$2-$3')
                //         vm.ruleForm.businessDeadlineStart = new Date(date0).getDate() ? date0 : ''
                //         vm.ruleForm.businessDeadline = new Date(date1).getDate() ? date1 : ''
                //         // this.form.fixedTerm = '1'
                //       }
                //     }
                //   }
                //   if (items[i].item === '经营范围') {
                //     vm.ruleForm.businessScope = itemss
                //   }
                //   if (items[i].item === '地址') {
                //     vm.ruleForm.businessAddress = itemss.substr(0, 50)
                //   }
                // }
              }
            }).catch(error => {
              console.log(error)
              ElMessage.error('营业执照识别失败！')
            })
          } else {
            ElMessage.error('营业执照上传失败')
          }
        }).catch(function () {
          ElMessage.error('营业执照上传失败')
          if (vm.loading) {
            vm.loading.close()
            vm.loading = ''
          }
        })
        return false
      } else {
        return isJPG && isLt2M
      }
    }
  }
}
</script>

<style scoped="scoped">
/**
  图片四个角的边框
 */
/*.image-angle-border{*/
  /*background: linear-gradient(to left, #E5E5E5, #E5E5E5) left top no-repeat,*/
  /*linear-gradient(to bottom, #E5E5E5, #E5E5E5) left top no-repeat,*/
  /*linear-gradient(to left, #E5E5E5, #E5E5E5) right top no-repeat,*/
  /*linear-gradient(to bottom, #E5E5E5, #E5E5E5) right top no-repeat,*/
  /*linear-gradient(to left, #E5E5E5, #E5E5E5) left bottom no-repeat,*/
  /*linear-gradient(to bottom, #E5E5E5, #E5E5E5) left bottom no-repeat,*/
  /*linear-gradient(to left, #E5E5E5, #E5E5E5) right bottom no-repeat,*/
  /*linear-gradient(to left, #E5E5E5, #E5E5E5) right bottom no-repeat;*/
  /*background-size: 1px 20px, 20px 1px, 1px 20px, 20px 1px;*/
/*}*/
.login-btn {
  width: 300px;
}
</style>
